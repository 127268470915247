<template>
  <div class="quotation">
    <div class="imgList">
      <Swiper
        class="imgListBox"
        :autoPlay="false"
        :showIndicator='false'
        @transtionend="getNum"
        v-if="pageData.carImageList.length > 0"
      >
        <Slide v-for="(item, index) in pageData.carImageList" :key="index">
          <img :src="item" alt="" />
        </Slide>
      </Swiper>

      <span class="imgNum">{{currentPage}}/{{ pageData.carImageList.length }}</span>
    </div>
    <div class="carInfo">
      <span class="name">{{ pageData.carName }}</span>
      <span class="price">
        <em>{{ pageData.quotationPrice }}万元</em>
        <em>报价有效期：{{ pageData.quotationValidityPeriod }}</em>
      </span>
    </div>

    <span class="pageTitle">估值建议价</span>
    <div class="recommendations">
      <div class="recommendations_item">
        <img src="../../assets/11.png" alt="" />
        <span>{{ carPrice.priceC2B + "-" + carPrice.priceB2C }}</span>
      </div>
      <div v-if="carPrice.publicPrice && carPrice.publicPrice.target" class="spiltLine"></div>
      <div v-if="carPrice.publicPrice && carPrice.publicPrice.target" class="recommendations_item">
        <img v-if="carPrice.publicPrice.target == 'qczj'" src="../../assets/52.png" alt="" />
        <img v-if="carPrice.publicPrice.target == '58'" src="../../assets/12.png" alt="" />
        <img v-if="carPrice.publicPrice.target == 'jzg'" src="../../assets/13.png" alt="" />
        <span
          >{{ carPrice.publicPrice.priceC2B || "数据维护中" }} -
          {{ carPrice.publicPrice.priceB2C || "数据维护中" }}</span
        >
      </div>
    </div>

    <span class="pageTitle">车辆信息</span>
    <div class="vehicleInformation">
      <span class="vehicleInformation_title">基本信息</span>
      <div class="vehicleInformation_box">
        <div class="vehicleInformation_box_item">
          <span>{{ pageData.registrationDate || "-" }}</span>
          <span>初次上牌</span>
        </div>
        <div class="vehicleInformation_box_item">
          <span>{{ pageData.drivenDistance || "-" }}公里</span>
          <span>表显里程</span>
        </div>
        <div class="vehicleInformation_box_item">
          <span>{{ pageData.emissionStdName || "-" }}</span>
          <span>排放标准</span>
        </div>
        <div class="vehicleInformation_box_item">
          <span>{{ pageData.plateCityName || "-" }}</span>
          <span>所在地</span>
        </div>
        <div class="vehicleInformation_box_item">
          <span>{{ pageData.transmissionType || "-" }}</span>
          <span>排量</span>
        </div>
        <div class="vehicleInformation_box_item">
          <span>{{ pageData.exteriorColorName || "-" }}</span>
          <span>颜色</span>
        </div>
      </div>
      <span class="vehicleInformation_title">车辆配置</span>
      <div class="vehicleInformation_content">
        <span v-for="(item, index) in pageData.carConfigList" :key="index">{{
          item
        }}</span>
      </div>
      <span class="detail_btn" @click="goConfig">查看详细配置</span>
    </div>

    <span class="pageTitle">车况描述</span>
    <div class="car_description">{{ pageData.remark }}</div>

    <div v-if="checkList.length>0">
      <span class="pageTitle">检测报告</span>
      <div class="header">
        <img class="header_img" src="../../assets/10.png" alt="" />
        <div class="car_name">{{ carInfo.vehicleName }}</div>

        <div class="gradeBox">
          <img class="gradeBoxIcon" src="../../assets/44.png" alt="">
          <span class="gradeBoxTip">评估检测报告：</span>
          <span class="gradeBoxResult"><em>{{ carInfo.evaluateDetectLRevel }}</em>({{ carInfo.evaluateDetectResult }})</span>
        </div>

        <div class="car_info">
          <div class="car_info_top">
            <div class="car_info_item">
              <span>{{ carInfo.exteriorInteriorScore }}</span>
            </div>

            <div class="car_info_item">
              <span>{{ carInfo.vehicleEvaluateRevel }}</span>
            </div>
          </div>
          <div class="car_info_bottom">
            <div class="car_info_item">
              <span>外观与内饰的评级得分</span>
            </div>
            <div class="car_info_item">
              <span>车辆评估结果</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <assessmentReportCom v-if="checkList.length>0" :checkArr="checkList" :flagId="flagId" :areaid="areaid" />
  </div>
</template>

<script>
import assessmentReportCom from "@/components/assessmentReportCom"
import { Swiper, Slide } from "vue-swiper-component";

import { quotationDetail, getPrice, getEvaluateReport } from "@/utils/api";
export default {
  components: {
    assessmentReportCom,
    Swiper,
    Slide,
  },
  data() {
    return {
      id: "",
      type: "vehicle",
      aresid: null,

      flagId: null,
      carInfo: {},
      checkList:[],

      currentPage:1,

      pageData: {
        carImageList: [],
        modelId: null,
        year: null,
        month: null,
        mileage: null,
        provinceid: null,
        provincename: null,
        cityid: null,
        cityname: null,
        groupid: null,
      },
      carPrice: {
        publicPrice: {},
      },
    };
  },
  computed: {},
  mounted() {
    this.id = this.$route.query.id;
    this.type = this.$route.query.type;
    this.areaid = this.$route.query.areaid;

    this.quotationDetail();
    this.getEvaluateReport();
  },
  methods: {
    getNum(data) {
      this.currentPage = data+1;
    },
    isAndroidOrIOS() {
      var u = navigator.userAgent;
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isAndroid) {
        return "android";
      }
      if (isiOS) {
        return "ios";
      }
      return false;
    },
    quotationDetail() {
      const that = this;
      let param = {
        id: that.id,
        isNoAuthorization: true,
      };
      quotationDetail(param).then((res) => {
        if (res.data == null) return;
        that.pageData = res.data;
        that.getPrice();
      });
    },
    getEvaluateReport() {
      const that = this;
      getEvaluateReport({
        vehicleId: that.id,
        isNoAuthorization: true,
        type: that.type,
        areaid: that.areaid,
      }).then((res) => {
        if(res.data){
          let data = res.data.detectList;
          that.flagId = that.areaid ? that.areaid : data[0].areaId;
          that.carInfo = res.data;
          that.checkList = data;
        }
      });
    },
    getPrice() {
      const that = this;
      let param = {
        modelid: that.pageData.modelId,
        year: that.pageData.carYear,
        month: that.pageData.plateMonth,
        mileage: that.pageData.drivenDistance,
        provinceid: that.pageData.provinceId,
        provincename: that.pageData.provinceName,
        cityid: that.pageData.cityId,
        cityname: that.pageData.cityName,
        groupid: that.pageData.groupId,
        device: that.isAndroidOrIOS(),
        remark: "",
      };
      getPrice(param).then((res) => {
        that.carPrice = res;
      });
    },
    goConfig() {
      const that = this;
      this.$router.push({
        path: "/carconfig",
        query: {
          id: that.id,
          modelId: that.pageData.modelId,
        },
      });
    },
  },
};
</script>
<style lang='scss'>
.wh_show_bgcolor {
  background: #327fff;
}

.swiper-container {
  height: 500px;
  width: 100%;
  .swiper-wrapper {
    .swiper-slide {
      width: 100%;
      height: 100%;
      background-color: #42b983;
      text-align: center;
      line-height: 500px;
    }
  }
}

.quotation {
  max-width: 750px;
  margin: 0 auto;
  overflow: hidden;
  background: #f3f5f7;
  padding-bottom: 30px;
  .imgList {
    width: 100%;
    height: 200px;
    position: relative;
    .imgListBox {
      width: 100%;
      height: 200px;
      display: block;
      background: #ffffff;
      border-bottom: 1px solid #dbdada;
      position: absolute;
      top: 0;
      left: 0;
      img {
        width: 100%;
        height: 200px;
        object-fit: cover;
      }
    }

    .imgNum {
      display: block;
      padding: 0 10px;
      line-height: 24px;
      position: absolute;
      right: 10px;
      bottom: 10px;
      z-index:99999;
      background: rgba($color: #000000, $alpha: 0.45);
      border-radius: 14px;
      font-family: PingFang-SC-Regular;
      font-size: 12px;
      color: #ffffff;
    }
  }
  .carInfo {
    padding: 15px;
    background: #ffffff;
    box-sizing: border-box;
    border-radius: 0 0 16px 16px;
    .name {
      display: inline-block;
      width: 100%;
      font-family: PingFang-SC-Medium;
      font-size: 14px;
      color: #332d29;
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .price {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      em:first-child {
        font-family: PingFang-SC-Medium;
        font-size: 16px;
        color: #ff6600;
        line-height: 18px;
        font-weight: 500;
      }
      em:last-child {
        width: 160px;
        height: 20px;
        background: rgba($color: #ff0000, $alpha: 0.1);
        border-radius: 16px 0px 0px 16px;
        font-family: PingFang-SC-Regular;
        font-size: 12px;
        color: #ff0000;
        text-align: center;
        line-height: 20px;
        margin-right: -15px;
      }
    }
  }
  .pageTitle {
    display: block;
    line-height: 45px;
    padding-left: 15px;
    box-sizing: border-box;
    background: #f3f5f7;
    font-family: PingFang-SC-Medium;
    font-size: 16px;
    color: #292d33;
    font-weight: bold;
  }
  .recommendations {
    box-sizing: border-box;
    padding: 15px 25px;
    border-radius: 16px;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .spiltLine {
      width: 1px;
      height: 50px;
      background: #e9e9e9;
    }
    .recommendations_item {
      display: flex;
      justify-content: center;
      flex-direction: column;
      flex-grow: 1;
      align-items: center;
      img {
        width: 145px;
        height: 45px;
      }
      span {
        display: inline-block;
        text-align: center;
        margin-top: 15px;
        font-family: PingFang-SC-Regular;
        font-size: 14px;
        color: #292d33;
      }
    }
  }
  .vehicleInformation {
    background: #ffffff;
    border-radius: 16px;
    padding: 15px;
    .vehicleInformation_title {
      display: block;
      font-family: PingFang-SC-Medium;
      font-size: 14px;
      color: #292d33;
      font-weight: bold;
    }
    .vehicleInformation_box {
      padding: 0 5px;
      margin-bottom: 15px;
      display: flex;
      flex-wrap: wrap;

      .vehicleInformation_box_item {
        width: 33.33333%;
        display: flex;
        flex-direction: column;
        margin-top: 12px;
        span {
          display: block;
          text-align: center;
          font-family: PingFang-SC-Regular;
        }
        span:first-child {
          font-size: 14px;
          color: #292d33;
        }
        span:last-child {
          margin-top: 8px;
          font-size: 12px;
          color: #979797;
        }
      }
    }
    .vehicleInformation_content {
      margin-top: 10px;
      span {
        line-height: 30px;
        display: inline-block;
        background: #f3f5f7;
        border-radius: 15px;
        padding: 0 10px;
        margin: 0 8px 8px 0;
        font-family: PingFang-SC-Regular;
        font-size: 12px;
        color: #292d33;
      }
    }
    .detail_btn {
      width: 100%;
      height: 37px;
      display: block;
      margin-top: 10px;
      background: rgba($color: #327fff, $alpha: 0.1);
      border-radius: 18.5px;
      font-family: PingFang-SC-Regular;
      font-size: 14px;
      color: #327fff;
      text-align: center;
      line-height: 37px;
    }
  }
  .car_description {
    border-radius: 16px;
    background: #ffffff;
    padding: 15px;
    font-family: PingFang-SC-Regular;
    font-size: 14px;
    color: #292d33;
    box-sizing: border-box;
  }
  .header {
    border-radius: 16px 16px 0 0;
    background: #ffffff;
    padding: 10px;
    position: relative;
    .header_img {
      width: 100%;
      display: block;
    }
    .car_name {
      position: absolute;
      left: 7%;
      top: 28px;
      width: 80%;
      font-family: PingFang-SC-Semibold;
      font-size: 18px;
      text-align: center;
      font-weight: 600;
      color: #292D33;
    }
    .gradeBox{
      position: absolute;
      top:45%;
      left:10%;
      display: flex;
      align-items: center;
      .gradeBoxIcon{
        height: 15px;
        width: 15px;
      }
      .gradeBoxTip{
        font-family: PingFang-SC-Regular;
        font-size: 12px;
        color: #292D33;
        font-weight: 400;
        margin-left: 7px;
      }
      .gradeBoxResult{
        font-family: PingFang-SC-Regular;
        font-size: 14px;
        color: #327FFF;
        font-weight: 400;
        em{
          font-style: normal;
          font-weight: 600;
        }
      }
    }
    .car_info {
      position: absolute;
      top: 68%;
      left: 0;
      right: 0;
      margin: auto;
      width: 80%;
      .car_info_top {
        display: flex;
        .car_info_item {
          width: 50%;
          display: inline-block;
          span {
            display: block;
            font-family: PingFang-SC-Semibold;
            font-size: 16px;
            color: #292D33;
            line-height: 22px;
            font-weight: 600;
          }
        }
      }
      .car_info_bottom {
        margin-top: 8px;
        .car_info_item {
          width: 50%;
          display: inline-block;
          span {
            margin-top: 5px;
            font-family: PingFang-SC-Regular;
            font-size: 12px;
            color: #979797;
            font-weight: 400;
          }
        }
      }
    }
  }
}
</style>